import * as React from "react"
import tw from "twin.macro"
import "@fontsource/inter"
import Seo from "../components/seo"
import Footer from "../components/footer"
import Carbonbadge from "react-carbonbadge"

const MlContainer = tw.div`
    text-black bg-white transition-all duration-300 dark:bg-black dark:text-white flex flex-wrap content-between min-h-screen
`

const MlPage = () => {
  return (
    <MlContainer>
      <Seo title="Mention légales" />
      <article className="prose lg:prose-xl dark:prose-invert dark:text-white" tw="px-4 py-10 mx-auto my-36 max-w-3xl sm:px-6" >
        <h1 tw="mb-16 text-center" >
          Mentions légales
        </h1>
        <Carbonbadge darkMode={true}  data-sal="fade" data-sal-delay="550" data-sal-duration="1000"/>
        <p tw="mt-10">
          Conformément aux dispositions des Articles 6-III et 19 de la Loi
          n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
          numérique, dite L.C.E.N., il est porté à la connaissance des
          Utilisateurs du site <em> thibaultlg.com</em> les présentes mentions
          légales. La connexion et la navigation sur le site
          <em> thibaultlg.com</em> par l’Utilisateur implique acceptation
          intégrale et sans réserve des présentes mentions légales. Ces
          dernières sont accessibles sur le site à la rubrique « Mentions légales ».
        </p>
        <h2 >Définitions</h2>
        <p >
          Contenu : Ensemble des éléments constituants l’information présente
          sur le Site, notamment textes – images – vidéos.
        </p>
        <p >
          Utilisateur : Internaute se connectant, utilisant le site susnommé.
        </p>
        <p >
          Informations personnelles : « Les informations qui permettent, sous
          quelque forme que ce soit, directement ou non, l’identification des
          personnes physiques auxquelles elles s’appliquent » (article 4 de la
          loi n° 78-17 du 6 janvier 1978).
        </p>
        <p >
          Les termes « données à caractère personnel », « personne concernée »,
          « sous traitant » et « données sensibles » ont le sens défini par le
          Règlement Général sur la Protection des Données (RGPD : n° 2016-679)
        </p>
        <h2 >ARTICLE 1: PROPRIETAIRE ET HÉBERGEUR</h2>
        <p >
          Le site <em> thibaultlg.com</em> est un site internet non
          professionnel.
        </p>
        <p >
          Le particulier qui crée un site internet n’est pas obligé de révéler
          son identité. Il doit cependant communiquer à l’hébergeur de son site
          les éléments qui permettront de l’identifier en cas de besoin.
          Vous pouvez contacter le responsable de publication via le lien « Contact ».
        </p>
        <p >
          L’hébergeur du site <em> thibaultlg.com</em> est Gitlab Pages - Gilab
          Inc. – 268 Bush Street, Suite 305, CA 94104, San Francisco, United
          States. <a href="https://gitlab.com/">https://gitlab.com/</a>
        </p>
        <h2 >ARTICLE 2: ACCÈS AU SITE</h2>
        <p >
          Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de
          force majeure, interruption programmée ou non et pouvant découler
          d’une nécessité de maintenance. En cas de modification, interruption
          ou suspension des services le site <em> thibaultlg.com</em> ne saurait
          être tenu responsable.
        </p>
        <h2 >
          ARTICLE 3: LIMITATIONS CONTRACTUELLES SUR LES DONNÉES TECHNIQUES
        </h2>
        <p >
          Le site utilise la technologie JavaScript. Le site Internet ne pourra
          être tenu responsable de dommages matériels liés à l’utilisation du
          site. De plus, l’utilisateur du site s’engage à accéder au site en
          utilisant un matériel récent, ne contenant pas de virus et avec un
          navigateur de dernière génération mis à jour.
        </p>
        <h2 >
          ARTICLE 4: COLLECTE DES DONNÉES, COOKIES, TRACEURS ET LIENS
          HYPERTEXTES
        </h2>
        <p >
          Le site <em> thibaultlg.com</em> ne collecte aucune donnée à caractère
          personnel ou information personnelle concernant les utilisateurs.
        </p>
        <p >


Un cookie est un petit fichier stocké par un serveur dans le terminal (ordinateur, téléphone, etc.) d’un utilisateur et associé à un domaine web (c’est à dire dans la majorité des cas à l’ensemble des pages d’un même site web). 
          Le site <em> thibaultlg.com</em> utilise uniquement des cookies nécessaires (dit fonctionnels) pour le fonctionnement du Site ou pour offrir le(s) service(s) demandé(s) par l'utilisateur. 
          Le site <em> thibaultlg.com</em> n'utilise pas des cookies à des fins publicitaires ou statistiques. 
        </p>
        <p >
          Le site <em> thibaultlg.com</em> contient un certain nombre de liens
          hypertextes vers d’autres sites, mis en place avec l’autorisation de
          <em> thibaultlg.com</em>. Cependant, <em> thibaultlg.com</em> n’a pas la
          possibilité de vérifier le contenu des sites ainsi visités, et
          n’assumera en conséquence aucune responsabilité de ce fait.
        </p>
        <h2 >ARTICLE 5: PROPRIÉTÉ INTELLECTUELLE</h2>
        <p >
          Toute utilisation, reproduction, diffusion, commercialisation,
          modification du Contenu ou de toute ou partie du site
          <em> thibaultlg.com</em>, sans autorisation de l’éditeur est prohibée
          et pourra entraîner des actions et poursuites judiciaires telles que
          notamment prévues par le Code de la propriété intellectuelle et le
          Code civil.
        </p>
        <h2 >ARTICLE 6 : DROIT APPLICABLE ET ATTRIBUTION DE JURIDICTION</h2>
        <p >
          Tout litige en relation avec l’utilisation du site
          <em> thibaultlg.com</em> est soumis au droit français. En dehors des
          cas où la loi ne le permet pas, il est fait attribution exclusive de
          juridiction aux tribunaux compétents français.
        </p>
      </article>
      <Footer />
    </MlContainer>
    
  )
}

export default MlPage
